import { DateTime } from "luxon";
import Base64 from "base-64";
import { restApi } from "../http/http.config";
import CryptoJS from 'crypto-js';

export const formatDate = (value) => {
  if (value) {
    return DateTime.fromISO(value, { zone: "utc" }).toFormat("dd LLL yyyy");
  }
};
export const formatDatePacific = (value) => {
  if (value) {
    return DateTime.fromISO(value).setZone("America/Los_Angeles").toFormat("dd LLL yyyy");
  }
};
export const formatDateTime = (value) => {
  if (value) {
    return DateTime.fromISO(value).toUTC().toFormat("dd LLL y - t");
  }
};

export const formatDateTimePacific = (value) => {
  if (value) {
    return DateTime.fromISO(value).setZone("America/Los_Angeles").toFormat("dd LLL y - t");
  }
};

export const formatDateDDMMMYYYY = (value) => {
  if (value) {
    return DateTime.fromISO(value?.split("T")[0]).toFormat("dd LLL y");
  }
};

export const formatDateDDMMM = (value) => {
  if (value) {
    return DateTime.fromISO(value).toFormat("dd MMM");
  }
};

export const formatPhone = (phone) => {
  if (!phone) {
    return "";
  } else {
    let phoneNumber = phone.replace(/[^0-9]/g, "");
    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
    } else {
      return phone;
    }
  }
};

export const formatSSN = (ssn) => {
  if (!ssn) {
    return "";
  } else {
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
  }
};

export const privateSSN = (ssn) => {
  if (!ssn) {
    return "";
  } else {
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, "XXX-XX-$3");
  }
};

export const titleCase = (value) => {
  if (value) {
    return value
      .split("_")
      .map((val) => {
        const lowerCase = val.toLowerCase();
        return lowerCase.replace(lowerCase.charAt(0), lowerCase.charAt(0).toUpperCase());
      })
      .join(" ");
  }
  return "";
};

export const formatMoney = (amount, space) => {
  amount == undefined || isNaN(amount) ? (amount = 0) : amount;
  if (!space) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount);
  } else {
    const formattedMoney = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount);
    return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(1, formattedMoney.length)}`;
  }
};

export const pretty = (value) => {
  return JSON.stringify(JSON.parse(value), null, 2);
};

export const formatTime = (value) => {
  if (value) {
    return DateTime.fromISO(value).setLocale("en").toFormat("t");
  }
};

export const formatNumber = (value) => {
  if (typeof value === "number" || typeof value === "string") {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};


export const encodeWithParam=(data)=> {
  const toStr= JSON.stringify(data)
  return {"param":Base64.encode(toStr)}
  };

  export const encode=(data)=> {
  const toStr= JSON.stringify(data)
  return Base64.encode(toStr)
  };

  export const decode=(data)=> {
  const str=Base64.decode(data)
  return JSON.parse(str)
  };


  export const downloadFile = (data, fileName) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([data], {type: "octet/stream"}),
    url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  export const encrypt =  async (val) => {
    let secret = await getKey()
    let key = CryptoJS.enc.Utf8.parse(secret.key);
    let iv = CryptoJS.enc.Utf8.parse(secret.iv_key.slice(0,16));
    let encrypted = CryptoJS.AES.encrypt(val, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC
    });
    let output = encrypted.ciphertext.toString();
    return iv+":"+output;
}

export const decrypt = async (val) => {
  try {
    let secret = await getKey()
    let key = CryptoJS.enc.Utf8.parse(secret.key);
    let keyVal = val.split(":");
    let iv = CryptoJS.enc.Hex.parse(keyVal[0]);
    let cipherText = CryptoJS.enc.Hex.parse(keyVal[1]);
    let options = { mode: CryptoJS.mode.CBC, iv: iv };
    let decrypted = CryptoJS.AES.decrypt({ciphertext: cipherText, iv: iv, salt: undefined }, key, options);
    let retVal = decrypted.toString(CryptoJS.enc.Utf8);
    return retVal
  } catch (err) {
    return val;
  }
}

async function getKey(){
  const { key, iv_key} = (await restApi.get('employee/getPPIKey')).data
  return {
    key: Base64.decode(key),
    iv_key: Base64.decode(iv_key)
    }
  }
  function deposItems(data,mapDetails){
    let arr=[];
    data.forEach((item)=>{
      arr.push({
                  "name": mapDetails[item.mapping_key] ?? mapDetails[item.mappingKey],
                  "amount": Number(item.payable_amount || item.totalAmount || item.amount),
                  "remarks": item.remarks,
                  "tax_exempted": item.tax_exempted,
              })
    })
    return arr
  }

  function formatDatePlain(date) {
    return DateTime.fromISO(date).toFormat("dd LLL yyyy");
  }

  function sumTotalAmount(transactions) {
    return transactions.reduce((sum, transaction) => sum + parseFloat(transaction.totalAmount), 0);
}

  export const reformatDepos = (data,mapDetails,user) => {
    let check = {
      "NET": {
          "name": "Net",
          "amount": 0
      },
      "DEBITS": {
          "name": "Debits",
          "items": [],
          "amount": 0
      },
      "CREDITS": {
          "name": "Credits",
          "items": [],
          "amount": 0
      },
      "WITHHOLDING": {
          "name": "Withholding",
          "items": [],
          "amount": 0
      },
      "BUSINESS_INCOME": {
          "name": "Business Income",
          "items": [],
          "amount": 0
      }
    }


    if(data.amount){
      check["NET"].amount = data.amount;
      check.BUSINESS_INCOME.items = data.adhocPaymentDetails ? deposItems(data.adhocPaymentDetails,mapDetails) : deposItems(data.dailyBreakup,mapDetails)
      check.BUSINESS_INCOME.amount = data.amount;
      return {
        check,
        id:data.index,
        "status": data.status,
        "disbursement_src": data.disbursement_src,
        "disbursement_date": data.disbursement_date,
        "totalAmount": data.amount,
        "settlement": {
            "startDate": data.startDate,
            "endDate": data.endDate
        },
        "partner": {
          "firstName": user.firstName,
          "lastName": user.lastName
        },
        "index": data.index,
        "payPeriod": `${formatDatePlain(data.startDate)} - ${formatDatePlain(data.endDate)}`,
        "pay_type": data.pay_type,
      }
    }
    else if(data.oneBreakup){
      let sum = sumTotalAmount(data.oneBreakup)
      check["NET"].amount = sum
      check.BUSINESS_INCOME.items = deposItems(data.oneBreakup,mapDetails)
      check.BUSINESS_INCOME.amount = sum
      return {
        check,
        id:data.index,
        "status": "Disbursed",
        "disbursement_src": data.disbursement_src,
        "disbursement_date": data.disbursement_date,
        "totalAmount": sum,
        "settlement": {
            "startDate": data.startDate,
            "endDate": data.endDate
        },
        "partner": {
            "firstName": user.firstName,
            "lastName": user.lastName
        },
        "index": data.index,
        "payPeriod": `${formatDatePlain(data.startDate)}`,
        "pay_type": data.pay_type,
      }
    }

  }

